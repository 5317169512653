    .h1 {
        font-size: 3rem !important;
        color: #00365a;
        margin-bottom: 2rem !important;
        font-weight: 400 !important;
    }
    
    .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .border-left {
        border-left: 4px #0c5088 solid;
        padding-left: 4rem !important;
        border-left-style: dotted;
    }
    
    .border-right {
        border-right: 4px #0c5088 solid;
        padding-right: 4rem !important;
        border-right-style: dotted;
    }
    
    .imagem-round {
        border-radius: 100%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        width: 300px;
        height: 300px;
    }
    
    .h3 {
        font-size: 1.8rem !important;
        color: #00365a !important;
        margin-bottom: 2rem !important;
    }
    
    .left-p {
        text-align: left;
        font-size: 1.1rem !important;
        line-height: 2rem !important;
        color: #8f8f8f !important;
    }
    
    .back-flip {
        width: 300px;
        height: 300px;
        border-radius: 100%;
        background: #FFF;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 47px;
    }
    
    .btn-saiba-mais {
        border: none;
        border-radius: 20px;
        color: #FFF;
        background: #19a97b;
        padding: 7px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 2rem;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    }
    
    @media only screen and (max-width: 700px) {
        .logo {
            max-width: 100% !important;
        }
        header,
        section {
            width: 100%;
            float: left;
            overflow: hidden;
        }
        .h1 {
            font-size: 1.25rem !important;
            font-weight: bold !important;
        }
        .imagem-round,
        .back-flip {
            width: 200px;
            height: 200px;
            font-size: 10px;
            margin: 0 auto;
        }
        .btn-saiba-mais {
            margin-top: 1rem;
        }
        .border-left {
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
        }
        .border-right {
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
        }
        ol,
        ul {
            padding-left: 0px !important;
        }
        .left-p,
        .right-p {
            padding: 15px;
            font-size: 1rem !important;
            text-align: justify !important;
        }
        .h3 {
            font-size: 1.25rem !important;
            margin-bottom: 0 !important;
            margin-top: 40px !important;
        }
        .lista-servico {
            background: white !important;
            border-radius: 6px !important;
            box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%) !important;
            padding-bottom: 25px !important;
            padding-top: 25px !important;
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }