.section-qualidades {
    background: #fff !important;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 150px;
    margin-bottom: 150px;
}

.section-qualidades::before {
    content: " ";
    width: 100%;
    height: 70px;
    position: absolute;
    background-size: cover;
    background-position: 50%;
    top: -70px;
    background-image: url('../../Imagens/before_div.svg');
    left: 0;
}

.section-qualidades::after {
    content: " ";
    width: 100%;
    height: 70px;
    position: absolute;
    background-size: cover;
    background-position: 50%;
    bottom: -70px;
    background-image: url('../../Imagens/after_div.svg');
    left: 0;
}

.imagem-qualidade {
    border-radius: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    width: 200px;
    height: 200px;
}

.flex-aling {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.margin-0 {
    margin-bottom: 0 !important;
}

@media only screen and (max-width: 700px) {
    .section-qualidades {
        margin-top: 24px !important;
    }
    .imagem-qualidade {
        width: 100px;
        height: 100px;
    }
}