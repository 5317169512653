.footer {
    /* background-image: url('../../Imagens/bottom_azul.svg'); */
    /* background: rgb(7, 44, 74); */
    background: rgb(186, 38, 255);
    background: linear-gradient(135deg, rgba(186, 38, 255, 1) 0%, rgba(46, 62, 232, 1) 50%, rgba(245, 29, 67, 1) 100%);
    /* height: 170vh; */
    /* background-color: #f0f0f0; */
    /* background-repeat: no-repeat; */
    padding-bottom: 0;
    /* background-size: cover;
    background-size: auto 1100px; */
    padding-top: 150px;
    /* background-position-y: -180px; */
    margin-top: 100px;
    /* background-position-x: -530.6px !important; */
    height: 100vh;
    position: relative;
}

.footer-dev {
    position: absolute;
    width: 100%;
    bottom: 10px;
    color: #FFF;
}

.line-container {
    min-height: 350px;
}

.flex-col {
    display: flex;
    justify-content: center;
    align-content: center;
}

.h-350 {
    min-height: 350px;
}

.h-550 {
    min-height: 350px;
    margin-top: -150px !important;
    text-align: left;
}

.text-just {
    text-align: justify;
    color: #fff !important
}

@media only screen and (max-width: 700px) {
    .footer {
        background: rgb(7, 44, 74);
        height: 170vh;
    }
    .hideOnMobile {
        display: none;
    }
    .col-sobre {
        margin-bottom: 2rem;
    }
    .margin-50 {
        margin-top: 10px !important;
    }
}