.navbar-barao {
    height: 120px;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
    z-index: 9;
    /* background-color: transparent !important; */
    width: 100%;
    color: #FFFF !important;
}

.navbar-light .navbar-nav .nav-link,
.navbar-brand {
    color: #FFFF !important;
}

.navbar-brand img:hover {
    transform: scale(1.3);
    transition: 1s;
}

.navbar-brand img {
    transition: 1s;
}

.ml-auto {
    margin-left: auto;
}

.nav-link {
    font-size: 1.35rem;
    padding: 15 !important;
    position: relative;
}

.nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: rgb(131, 58, 180);
    background: linear-gradient(135deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    border-radius: 20px;
}

.nav-link:hover::after {
    width: 100%;
}