.button-menu {
    position: absolute;
    top: 45px;
    right: 25px;
    z-index: 9999;
    color: #FFF;
    font-size: 35px;
    display: none !important;
}


/* .css-i4bv87-MuiSvgIcon-root {
    display: none !important;
} */

.icon-menu {
    width: 100%;
    background: #072d4d;
    padding: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    margin-top: -15px;
}

@media only screen and (max-width: 700px) {
    .button-menu {
        display: block !important;
    }
    .css-i4bv87-MuiSvgIcon-root {
        display: inline-block !important;
    }
    a {
        color: inherit !important;
    }
}