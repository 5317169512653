.page-header {
    padding-top: 2rem;
    background: #FFF;
    width: 95vw;
    margin: 0 auto;
    margin-top: 0rem;
    min-height: calc(100vh - 200px);
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 3rem;
}

.bread {
    position: relative;
}

.bread::after {
    content: none;
    width: 100%;
    height: 2px;
    background: rgb(12 80 136);
    position: absolute;
    bottom: -10px;
    left: 0;
}

.bread-link {
    color: goldenrod;
}

.div-container {
    background: #FFF;
    height: 100vh;
    width: 95vw;
    margin: 0 auto;
    /* margin: -60px 30px 0; */
    z-index: 9999;
    margin-top: -250px;
    border-radius: 6px;
    padding: 25px;
    box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    margin-bottom: 100px;
}

.section-header-page {
    height: 50vh;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    background: #072c4a;
    z-index: -1;
}

.titulo-page {
    position: absolute;
    z-index: 9;
    color: white;
    right: 0;
    text-align: right;
    padding-right: 12rem;
}

.titulo-page h1 {
    position: relative;
}

.titulo-page h1::after {
    content: '';
    height: 5px;
    border-radius: 10px;
    width: 100%;
    position: absolute;
    bottom: -10px;
    left: 0;
    z-index: 999;
    background: linear-gradient(135deg, rgb(187, 224, 83) 0%, rgb(214, 156, 156) 50%, rgb(242, 246, 3) 100%);
    margin-top: 5px;
    animation: h1 3s infinite alternate-reverse;
}

@keyframes h1 {
    0% {
        width: 1%;
    }
    100% {
        width: 100%;
        right: 0;
    }
}

@media only screen and (max-width: 700px) {
    .titulo-page {
        padding-right: 15px !important;
    }
    .titulo-page h1 {
        font-size: 1.25rem;
    }
    .div-container {
        margin-top: 50px
    }
}