.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ps-container>.ps-scrollbar-x-rail>.ps-scrollbar-x {
    transition: .2s linear left;
    /* maybe other vendor-prefixed transitions */
}

.ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y {
    transition: .2s linear top;
    /* maybe other vendor-prefixed transitions */
}

@media only screen and (max-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        zoom: 0.9 !important;
    }
    .footer {
        background-image: none !important;
        background-color: #083052 !important;
    }
}


/* @media only screen and (min-width: 600px) {
    html {
        zoom: .8;
    }
}

@media only screen and (min-width: 800px) {
    html {
        zoom: .9;
    }
}

@media only screen and (min-width: 1400px) {
    html {
        zoom: 1;
    }
} */