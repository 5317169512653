/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap'); */

@import 'react-perfect-scrollbar/dist/css/styles.css';
.section-header {
    background-image: url('../../Imagens/topo_azul.svg');
    background-repeat: no-repeat;
    background-position-y: 0;
    background-color: initial;
    height: 1440px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    background-size: cover;
    background-position-x: -520.4px;
}

.header-title {
    margin-top: 40px;
    text-align: left;
}

.header-title .title {
    font-size: 3rem;
    line-height: 3.2rem;
    margin-bottom: 1.1em;
    color: #fff;
}

.header-title p {
    color: #fff;
    font-size: 1.1rem;
}

.white {
    color: #fff!important;
}

.btn-b {
    border-radius: 50px;
    outline: initial!important;
    box-shadow: none!important;
    font-size: 1.2rem;
    padding: .5rem 1.25rem;
    font-weight: 500;
    letter-spacing: .05rem;
    padding: 1rem 3.5rem .9rem;
}

.btn-light {
    color: #131113;
    background-color: #ececec;
    border-color: #ececec;
}

.btn {
    border-radius: 50px;
    outline: initial!important;
    box-shadow: none!important;
    font-size: .8rem;
    padding: .5rem 1.25rem;
    text-decoration: none !important;
}

a {
    text-decoration: none !important;
}

.btn-group-xl>.btn,
.btn-xl {
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: .05rem;
    padding: 1rem 3.5rem .9rem;
}

.btn-light:hover {
    color: red;
    background-color: #d8d8d8;
    border-color: #d8d8d8;
    transform: scale(7.5) !important;
}

.btn-lights {
    color: #131113;
    background-color: #ececec;
    border-color: #ececec;
}

.btn-lights:hover {
    color: #131113;
    background-color: #d8d8d8;
    border-color: #d8d8d8;
    transform: scale(7.5) !important;
}

body {
    font-family: "Nunito", sans-serif !important;
    background-color: #f0f0f0 !important;
}

.header-row {
    padding-top: 180px;
    margin-bottom: 70px;
}

.logo {
    width: 75vh;
}

.box-title-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.showinMobile {
    display: none;
}

html,
body {
    overflow-x: hidden;
}

.modalForm {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: '50%';
    background-color: 'background.paper';
    border: '2px solid #000';
    box-shadow: 24;
    padding: 4px;
}

@media only screen and (max-width: 700px) {
    .modalForm {
        width: 100%;
        overflow: scroll;
        height: 95vh;
    }
    .logo {
        max-width: 100% !important;
    }
    header,
    section {
        width: 100%;
        float: left;
        overflow: hidden;
    }
    .h1 {
        font-size: 1.25rem !important;
        font-weight: bold !important;
    }
    .showinMobile {
        display: block;
    }
    .hideinMobile {
        display: none;
    }
    .showinMobile .title {
        text-align: center !important;
        font-size: 2rem !important;
    }
    .p-header {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 1rem;
    }
    .btn-lights {
        margin: 0 auto;
        position: absolute;
        width: 100%;
        left: 0;
        text-align: center;
    }
    .header-title {
        margin-top: 20vh !important;
    }
}

.card-simulator {
    /* width: 100%; */
    height: 500px;
    background: #FFF;
    border-radius: 30px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.div-center_ {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 80px;
}

.input-fgts {
    border: 1px #c1c1c1 solid;
    border-radius: 5px;
    height: 50px;
    background: #EEEEEE;
    width: 100%;
    padding: 20px;
}

.money-presentation {
    font-size: 35px;
    font-weight: bold;
    color: #c3256c;
}

.label-fgts {
    text-align: left;
    margin-bottom: 15px;
    margin-top: 18px !important;
    font-weight: bold;
}

.label-fgts .ol {
    margin-bottom: 0px !important;
}

.button-fgts {
    height: 60px !important;
    width: 100%;
    margin-top: 30px !important;
    border-radius: 60px !important;
    font-size: 27px !important;
    font-weight: bold !important;
}

.valueOfSimulation {
    font-size: 70px;
    color: #B32879 !important;
}