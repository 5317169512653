.modalForm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
    padding: 2rem;
    width: 50%;
}

.modalForm:active, .modalForm::selection, .modalForm:hover, .modalForm:focus-visible, .modalForm:focus{
    border: none;
    outline: none !important
}

.modalForm img{
    width: 80%;
}

@media only screen and (max-width: 900px) {
    .modalForm {
        width: 100%;
        overflow: scroll;
        height: 100vh;
    }

    .modalForm img{
        width: 100% !important;
    }
    .buttonClose {
        display: block !important;
    }
}

.buttonClose {
    background: red !important;
    width: 35px !important;
    margin-bottom: 1rem !important;
    height: 35px !important;
    display: none
}